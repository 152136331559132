
.featured-dapp {
    /* White */
    display: flex;
    flex:1;
    align-items: center;
    flex-direction: column;
    padding-right: 20px;
    box-sizing: content-box;
    text-decoration: none;
}

.featured-dapp:active{
    opacity: 0.5
}

.featured-dapp-box{
    height: 65px;
    width: 65px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.featured-dapp-logo {
    height: 38px;
    width: 38px;
    margin-top: 13px;
    border-radius: 100px;
}

.featured-dapp-name {
    margin-top: 12px;
    width: 65px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    padding-bottom: 0px;
    margin-bottom: 0px;
}


