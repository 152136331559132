.take-a-tour-wrapper {
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.take-a-tour {
    display: flex;
    flex: 1;
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 16px;
}

.take-a-tour-logo {
    margin-right: 16px;
    width: 38px;
    height: 38px;
    padding: 5px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.take-a-tour-content {
    display: flex;
    flex: 1;
    flex-direction: column;
}


.take-a-tour-content h3{
    display: flex;
    flex: 1;
    color: #24292E;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 5px;
}

.take-a-tour-content p{
    display: flex;
    flex: 1;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #24292E;
    margin-top: 0px;
}

.take-a-tour-close {
    width: 16px;
    height: 16px;
    padding: 5px;
    position: absolute;
    top: 5px;
    right: 0;
    text-align: center;
    background-color: transparent;
    display: block;
    box-sizing: content-box;
    border: 0px;
}