.tab-list {
    border-bottom: 1px solid #D6D9DC;
    padding-left: 0;
    display: flex;
    flex: 1;
    flex-direction: row;
}

.tab-content {
    display: flex;
    flex: 1;
    padding-left: 8px;
    padding-right: 8px;
}
