.featured-dapps{
    margin-bottom: 30px;
}
.featured-dapps-title{
    padding-left: 16px;
    padding-right: 16px;
    font-size: 0.9rem;
    text-align: left;
    color: #6A737D;
    margin-bottom: 5px;
    font-weight: 500;
}

.featured-dapps-carousel-wrapper {
    overflow-y: hidden;
    overflow-x: scroll;
    height: 102px;
    width: 100%;
}

.featured-dapps-carousel {
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow-x: scroll;
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
    -webkit-overflow-scrolling: touch;
    box-sizing: content-box; 
}
