.dapp {
    position: relative;
}

.dapp-container {
    display: flex;
    margin-bottom: 15px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    flex-direction: row;
}

.dapp:active {
    opacity: 0.5
}

.dapp-logo {
    margin-top: 2px;
    width: auto;
    min-width: 48px;
    height: 48px;
    border-radius: 9px;
    margin-right: 16px;
}

.dapp-logo-small {
    min-width: 32px;
    height: 32px;
}

.dapp-name {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    align-items: center;
    color: #24292E;
    text-align: left;
    margin-bottom: 3px;
    margin-top: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dapp-name-small {
    font-size: 16px;
    line-height: 22px;
}

.dapp-desc {
    margin-top: 0px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    text-align: left;
    color: #6A737D;
}

.dapp-content {
    width: 80%;
}

.dapp-url {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    text-align: left;
    color: #037DD6;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}


.dapp-close {
    z-index: 1;
    width: 16px;
    height: 16px;
    padding: 5px;
    position: absolute;
    top: 5px;
    right: 0;
    text-align: center;
    background-color: transparent;
    display: block;
    box-sizing: content-box;
    border: 0px;
}