.header {
    z-index: 0;
    height: 170px;
    display: flex;
    flex: 1;
    margin-bottom: 55px;
    position: relative;
    align-items: center;
    justify-content: center;
    text-align: center;

}

.header .bg-img{
    height: 170px;
    width: 100%;
    margin-top: 85px;
}

.header .logo-img{
    background-color: transparent;
    position: absolute;
    height: 66px;
    width: 202px;
    z-index: 999999999;
    align-self: center;
    justify-content: center;
    margin-top: 40px;
}

